<template>
    <div class="result"  ref="container">

        <div id="divcss5">
            <van-sticky :container="container" >
                <van-form @submit="onSubmit">
                    <van-field
                            v-model="phone"
                            type="tel"
                            name="手机号"
                            label="手机号"
                            placeholder="请输入手机号"
                            :rules="[{ validator:validator, message: '请输入正确内容'}]"
                    />
                    <div style="margin-top: 30px;">
                        <van-button round block style="background-color: #94cdfa;" native-type="submit">提交</van-button>
                    </div>
                </van-form>

            </van-sticky>


        </div>
    </div>
</template>

<style>
    .result{
        background:url("../../assets/couponbj1.png");
        width:100%;
        height:100%;
        position:fixed;
        background-size:100% 100%;}

    #divcss5 {
        margin:0 auto;margin-top:137%;width:280px;height:100px
    }
</style>



<script>
    import { getPhoneNumber } from "@/api/login";
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Button } from 'vant';
    import { Dialog, Image } from 'vant';
    Vue.use(Form);
    Vue.use(Field);
    Vue.use(Button);
    Vue.use(Image);


    import { Sticky } from 'vant';

    Vue.use(Sticky);

    export default {
        data() {
            return {
                phone: '',
                container: null,
            };
        },
        mounted() {
            this.container = this.$refs.container;
        },
        methods: {
            // 校验函数返回 true 表示校验通过，false 表示不通过
            validator(val) {

                return /^1(3|4|5|7|8|9)\d{9}$/.test(val);
            },
            onSubmit(values) {
                getPhoneNumber(this.phone,7).then(response => {
                    const code = response.code;
                    console.log(code);
                    if(code == 666){
                        Dialog({ message: '请重新领取' });
                    }else if(code == 667 ){//今日已领取
                        Dialog({ message: '抱歉，您领取优惠的次数已超限' });
                    }else if(code == 668){//未注册
                        Dialog.confirm({ message: '很遗憾，您还不是云闪付用户。点击“确定”，下载云闪付，注册绑卡后，参与活动' })
                            .then(() => {
                                window.location.href = "https://youhui.95516.com/hybrid_v4/html/help/download.html";
                            })
                            .catch(() => {
                            });
                    }else if(code == 669){
                        Dialog({ message: '很遗憾，券已领取完毕，欢迎明天再次参与' });
                    }else if(code ==670 || code == 671){//活动过期或者优惠券不存在  活动id错误  系统错误
                        Dialog({ message: '您抢券的时间不在活动时间范围之内，更多详细规则，可联系客服：0631-86165717' });
                    }else if(code == 200){
                        Dialog({ message: '恭喜您抢券成功，请前往云闪付APP“我的”-“奖励”查看票券，并进行核销使用' });
                    }


                }).catch((e) => {});
            },
        },
    };
</script>

